.KA-bg-green {
  background: $green;
}

.errorInput {
  border: 1px solid red;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.025);
  }
}
