// for pc
@media (min-width: 1020px) {
    section.placeOrder {
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding-top: 50px;

        div.leftSection {
            width: 48%;

            div.orderInside {
                width: 100%;
                // overflow: hidden;
                border: 1px solid rgba(0, 0, 0, 0.125);
                border-radius: 10px;

                span.Toggler {
                    height: 40px;
                    width: 100%;
                    @include flex;
                    justify-content: flex-start;
                    padding: 0px 30px; 
                    font-size: 17px;
                    cursor: pointer;

                    &:hover {
                        color: $base-purple;
                    }
                }
            }
        }
        div.rightSection {
            width: 48%;
            height: 800px;

            iframe {
                height: 100%;
                width: 100%;
            }
        }
    }
}
// for tab
@media (min-width: 760px) and (max-width: 1020px) {
    section.placeOrder {
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding-top: 50px;

        div.leftSection {
            width: 48%;

            div.orderInside {
                width: 100%;
                // overflow: hidden;
                border: 1px solid rgba(0, 0, 0, 0.125);
                border-radius: 10px;

                span.Toggler {
                    height: 40px;
                    width: 100%;
                    @include flex;
                    justify-content: flex-start;
                    padding: 0px 30px;
                    font-size: 17px;
                    cursor: pointer;

                    &:hover {
                        color: $base-purple;
                    }
                }
            }
        }
        div.rightSection {
            width: 48%;
            height: 800px;

            iframe {
                height: 100%;
                width: 100%;
            }
        }
    }
}
// for mob
@media (max-width: 760px) {
    section.placeOrder {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10px;
        padding-top: 50px;

        div.leftSection {
            width:100%;
            @include flex;
            flex-direction: column;

            div.orderInside {
                width: 100%;
                // overflow: hidden;
                border: 1px solid rgba(0, 0, 0, 0.125);
                border-radius: 10px;

                span.Toggler {
                    height: 40px;
                    width: 100%;
                    @include flex;
                    justify-content: flex-start;
                    padding: 0px 30px;
                    font-size: 17px;
                    cursor: pointer;

                    &:hover {
                        color: $base-purple;
                    }
                }
            }
        }
        div.rightSection {
            width: 100%;
            height: 800px;

            iframe {
                height: 100%;
                width: 100%;
            }
        }
    }
}
